import httpClient from './httpClient';

enum DeliveryMethod {
  HTTP = 'http',
  EMAIL = 'email',
}

enum CallbackEventName {
  FULFILLMENT_COMPLETED = 'fulfillment_completed',
  CANCELLATION_COMPLETED = 'cancellation_completed',
  INVOLUNTARY_CHANGE = 'involuntary_change',
  INVOLUNTARY_CANCELLATION = 'involuntary_cancellation',
  CUSTOMER_ACTION_NEEDED = 'customer_action_needed',
  ORDER_CHECK_COMPLETED = 'order_check_completed',
  REFUND_CHECK_COMPLETED = 'refund_check_completed',
  AUTOMATIC_CHECK_IN_COMPLETED = 'automatic_check_in_completed',
}

interface createCallbackData {
  delivery_method: DeliveryMethod;
  target: string;
  event_name: CallbackEventName;
}

const getCallbackSubscriptions = (integrationId: string) =>
  httpClient.get(`/integration/${integrationId}/callback_subscription`);

const createCallback = (
  integrationId: string,
  callbackData: createCallbackData
) =>
  httpClient.post(
    `/integration/${integrationId}/callback_subscription`,
    callbackData
  );

const disableCallback = (integrationId: string, callbackId: string) =>
  httpClient.delete(
    `/integration/${integrationId}/callback_subscription/${callbackId}`
  );

export {
  getCallbackSubscriptions,
  createCallback,
  disableCallback,
  DeliveryMethod,
  CallbackEventName,
};
