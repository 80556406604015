
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ChevronRightIcon } from '@heroicons/vue/outline';
import { XCircleIcon } from '@heroicons/vue/solid';
import store from '@/store';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { object, string } from 'yup';
import TextField from '@/components/TextField.vue';
import {
  createCallback,
  DeliveryMethod,
  CallbackEventName,
} from '@/api/callbacks.api';

export default {
  components: {
    ChevronRightIcon,
    SystemStatusBadge,
    XCircleIcon,
    Form,
    TextField,
    Field,
    ErrorMessage,
  },
  setup(): Record<string, unknown> {
    const sharedState = store.state;
    const router = useRouter();
    var deliveryMethod = '';
    const onSubmit = async (values: any) => {
      try {
        await createCallback(sharedState.integration.id, values);
        router.push({ path: `/organization` });
      } catch (e) {
        error.value = 'Something went wrong creating a callback subscription';
      }
    };

    const onChange = (event: any) => {
      deliveryMethod = event.target.value;
      placeholder.value =
        deliveryMethod === 'email'
          ? 'example@email.com'
          : 'https://example.com/callback';
    };

    const error = ref('');
    const loading = ref(false);
    const placeholder = ref('https://example.com/callback');

    const schema = object().shape({
      delivery_method: string().required('Delivery method is required'),
      target: string().when('delivery_method', {
        is: 'email',
        then: string()
          .email('Invalid email value')
          .required('Target is required'),
        otherwise: string().required('Target is required'),
      }),
      event_name: string().required('Event name is required'),
    });

    return {
      error,
      loading,
      onSubmit,
      onChange,
      schema,
      DeliveryMethod,
      CallbackEventName,
      placeholder,
    };
  },
};
